/* eslint-disable @typescript-eslint/no-misused-promises */
import { createParser } from "css-selector-parser";
import {
  FC,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import {
  PluginField,
  pluginCoreFieldHints,
  pluginDomSelectorFields,
} from "../../constants";
import { CoreContext } from "../../contexts";
import { useDisplayAlert } from "../../hooks";
import useSearchParams from "../../hooks/useSearchParams";
import { getPlugin, testPlugin, setPlugin, deletePlugin } from "../../requests";
import { getDomainRules, resetCache } from "../../requests/plugins";
import { IProduct } from "../../types";
import { IPlugin, IPluginForm } from "../../types/plugins";
import { normalizePlugin, parseDomain } from "../../utils";
import Header from "../Header";
import Loader from "../Loader";
import ScrollToTop from "../ScrollToTop";
import Tooltip from "../Tooltip";

const validateImagesSelector = (ds: IPlugin["domSelectors"]) => {
  if (!ds || !ds.images) {
    return true;
  }
  const validHeightParam = ds.images.heightParam
    ? ds.images.heightParam.name
      ? !!ds.images.heightParam.value
      : true
    : true;
  return ds.images.widthParam
    ? ds.images.widthParam.name
      ? !!ds.images.widthParam.value && validHeightParam
      : validHeightParam
    : validHeightParam;
};

const DEFAULT_VALUE = { whitelisted: [], blacklisted: [] };

const Plugin: FC = () => {
  const { state, dispatch } = useContext(CoreContext);
  const params: { domain: string } = useParams();
  const [domainRules, setDomainRules] = useState<{
    whitelisted: string[];
    blacklisted: string[];
  }>(DEFAULT_VALUE);
  const [loading, setLoading] = useState(false);
  const [testCleared, setTestCleared] = useState(true);
  const [showSources, setShowSources] = useState(false);
  const [testResponse, setTestResponse] = useState<IProduct | string>();
  const { pathname } = useLocation();
  const searchParams = useSearchParams();
  const history = useHistory();
  const { displayAlert } = useDisplayAlert();

  const { handleSubmit, register, reset, setValue } = useForm<IPluginForm>();

  // Effect for getting plugin data and setting the form state
  useEffect(() => {
    async function getPluginFromApi() {
      if (params.domain === "new") {
        return;
      }

      setLoading(true);

      const plugin = await getPlugin(params.domain, state.apiKey);

      if (!plugin) return;

      setDomainRules(await getDomainRules(params.domain, state.apiKey));

      const sp = searchParams.get("product");

      reset(plugin);

      if (plugin.sources) {
        setShowSources(true);
      }

      if (sp || plugin.productUrl) {
        setValue("targetUrl", sp ? decodeURIComponent(sp) : plugin.productUrl);
      }

      setValue("proxyGeoCountryCode", "DK");

      setLoading(false);
    }

    void getPluginFromApi();
  }, [params.domain, reset, state.apiKey, setValue, searchParams]);

  // Effect for setting the form state when adding a new plugin
  useEffect(() => {
    if (params.domain !== "new") {
      return;
    }

    reset();

    const sd = searchParams.get("domain");
    const sp = searchParams.get("product");

    if (sd) {
      setValue("domain", decodeURIComponent(sd));
    }

    if (sp) {
      setValue("productUrl", decodeURIComponent(sp));
      setValue("targetUrl", decodeURIComponent(sp));
    }
    setValue("proxyGeoCountryCode", "DK");
    setValue("page.waitUntil", "domcontentloaded");
  }, [params.domain, reset, setValue, searchParams]);

  const delPlugin = async () => {
    dispatch({
      type: "set-loading",
    });
    await deletePlugin(params.domain, state.apiKey);
    dispatch({
      type: "remove-loading",
    });
    void history.push("/plugins");
  };

  const onResetCache = async () => {
    dispatch({
      type: "set-loading",
    });
    const result = await resetCache(params.domain, state.apiKey);
    dispatch({
      type: "remove-loading",
    });

    displayAlert({
      type: result ? "success" : "error",
    });
  };

  const test = async (d: IPluginForm) => {
    if (state.loading) {
      return;
    }
    if (!validateImagesSelector(d.domSelectors)) {
      displayAlert({
        type: "error",
        message:
          "Images domSelector validation failed. Did you define the values for the params?",
      });
      return;
    }

    if (!d.targetUrl) {
      displayAlert({
        type: "error",
        message: "Target URL cannot be empty.",
      });
      return;
    }

    dispatch({
      type: "set-loading",
    });
    const plugin = normalizePlugin(d);

    const product = await testPlugin(
      {
        targetUrl: d.targetUrl,
        proxyGeoCountryCode: d.proxyGeoCountryCode || "DK",
        ...plugin,
      },
      state.apiKey
    );

    setTestResponse(product);

    setTestCleared(false);
    dispatch({
      type: "remove-loading",
    });
  };

  const checkDomain = (s: string) => {
    const dom = parseDomain(s);
    if (dom) {
      setValue("domain", dom);
    }
  };

  const baseField = (f: PluginField, child: ReactNode) => (
    <Fragment key={f.id}>
      {f.dividerBefore && <hr style={{ width: "85%" }} />}
      <div className="field" key={f.id}>
        <p>{f.label}</p>
        {child}
        <Tooltip text={f.hint} size={f.hint.length > 10 ? 400 : 100} />
      </div>
    </Fragment>
  );

  const renderField = (f: PluginField) => {
    if (f.type === "select") {
      return baseField(
        f,
        <select {...register(f.id)}>
          <option value="" />
          {f.options?.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      );
    }
    if (f.type === "switch") {
      return baseField(
        f,
        <label className="switch">
          <input type="checkbox" {...register(f.id)} />
          <span className="slider" />
        </label>
      );
    }
    return baseField(f, <input type="text" {...register(f.id)} />);
  };

  const save = async (d: IPluginForm) => {
    if (state.loading) {
      return;
    }
    if (!validateImagesSelector(d.domSelectors)) {
      displayAlert({
        type: "error",
        message:
          "Images domSelector validation failed. Did you define the values for the params?",
      });
      return;
    }
    dispatch({
      type: "set-loading",
    });
    dispatch({
      type: "remove-alert",
    });

    const plugin = normalizePlugin(d);
    if (!plugin.domain) {
      displayAlert({
        type: "error",
        message: "Domain cannot be empty.",
      });
      return;
    }

    let selectorsValid = true;
    try {
      const parse = createParser();
      const selectors = [
        plugin.page?.waitForSelector,
        ...Object.values(plugin.domSelectors ?? {}).map((s) =>
          s && "dom" in s ? s.dom : ""
        ),
      ].filter((s): s is string => typeof s === "string" && !!s);
      selectors.forEach((s) => {
        const selector = parse(s);
        if (!selector) {
          selectorsValid = false;
        }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      selectorsValid = false;
    }

    if (!selectorsValid) {
      dispatch({
        type: "remove-loading",
      });
      displayAlert({
        type: "error",
        message:
          "Invalid CSS selectors. If you believe this is an error, please contact vb@onskeskyen.dk.",
      });
      return;
    }

    const success = await setPlugin(plugin, state.apiKey);

    dispatch({
      type: "remove-loading",
    });
    displayAlert({
      type: success ? "success" : "error",
    });

    // Redirect if new domain on success
    if (pathname === "/plugins/new" && success) {
      history.push("/plugins/" + plugin.domain);
    }
  };

  return (
    <div className="padding max-width">
      <form onSubmit={handleSubmit(save)}>
        <Header
          leftButton={() => (
            <Link to="/plugins" className="white white-btn small-padding">
              Back
            </Link>
          )}
          title={
            !params.domain || params.domain === "new"
              ? "Add new domain"
              : "Domain: " + params.domain
          }
          rightButton={() => (
            <button
              className="btn-white"
              disabled={state.loading}
              type="submit"
            >
              Save
            </button>
          )}
        />
        {loading ? (
          <Loader center />
        ) : (
          <div className="grid">
            <div style={{ paddingRight: 10 }}>
              <div className="field">
                <p>Product URL</p>
                <input type="text" {...register("productUrl")} />
                <Tooltip
                  text={pluginCoreFieldHints["productUrl"] || ""}
                  size={500}
                />
              </div>
              <div className="field">
                <p>Domain</p>
                <input
                  type="text"
                  {...register("domain", {
                    onBlur: ({ target }) =>
                      checkDomain(
                        (target as unknown as { value?: string }).value || ""
                      ),
                  })}
                />
                <Tooltip
                  text={pluginCoreFieldHints["domain"] || ""}
                  size={500}
                />
              </div>
              <div className="field">
                <p>User-Agent</p>
                <input
                  placeholder="undefined"
                  type="text"
                  {...register("page.userAgent")}
                />
                <Tooltip
                  text={pluginCoreFieldHints["page.userAgent"] || ""}
                  size={400}
                />
              </div>
              <div className="field">
                <p>Page wait for Selector</p>
                <input type="string" {...register("page.waitForSelector")} />
                <Tooltip
                  text={pluginCoreFieldHints["page.waitForSelector"] || ""}
                  size={600}
                />
              </div>
              <div className="field">
                <p>Page wait for Timeout</p>
                <input
                  placeholder="0"
                  type="number"
                  {...register("page.waitForTimeout")}
                />
                <Tooltip
                  text={pluginCoreFieldHints["page.waitForTimeout"] || ""}
                  size={600}
                />
              </div>
              <div className="field">
                <p>Wait until</p>
                <select {...register("page.waitUntil")}>
                  <option value="domcontentloaded">domcontentloaded</option>
                  <option value="networkidle0">networkidle0</option>
                  <option value="networkidle2">networkidle2</option>
                </select>
                <Tooltip
                  text={pluginCoreFieldHints["page.waitUntil"] || ""}
                  size={700}
                />
              </div>
              <div className="field">
                <p>Proxy</p>
                <select {...register("proxy")}>
                  <option value="">disabled</option>
                  <option value="datacenter">datacenter</option>
                  <option value="unlocker">
                    unlocker (only if datacenter does not work)
                  </option>
                  <option value="newunlocker">
                    newunlocker (do not select this 🥲)
                  </option>
                  <option value="static">
                    static (34.78.233.125 - only if whitelisted)
                  </option>
                  <option value="residential">
                    residential (only if whitelisted by BD)
                  </option>
                </select>
                <Tooltip
                  text={pluginCoreFieldHints["proxy"] || ""}
                  size={700}
                />
              </div>
              <div className="field">
                <p>Image Proxy</p>
                <select {...register("imageProxy")}>
                  <option value="">disabled</option>
                  <option value="datacenter">datacenter</option>
                  <option value="unlocker">
                    unlocker (only if datacenter does not work)
                  </option>
                  <option value="newunlocker">
                    newunlocker (do not select this 🥲)
                  </option>
                  <option value="static">
                    static (34.78.233.125 - only if whitelisted)
                  </option>
                  <option value="residential">
                    residential (only if whitelisted by BD)
                  </option>
                </select>
                <Tooltip
                  text={pluginCoreFieldHints["imageProxy"] || ""}
                  size={700}
                />
              </div>
              <div>
                {renderField({
                  id: "allowJquery",
                  label: "Allow jQuery",
                  type: "switch",
                  hint: "Allows jQuery resources to load on site.",
                  hide: false,
                })}
              </div>
              <div>
                {renderField({
                  id: "enableGifs",
                  label: "Enable GIFs",
                  type: "switch",
                  hint: "Allows GIF resources to be returned from the site.",
                  hide: false,
                })}
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 20,
                  }}
                >
                  <h4 style={{ margin: "10px" }}>
                    Override sources to ldjson or opengraph
                  </h4>
                  <button
                    type="button"
                    className="btn-white"
                    onClick={() => setShowSources((o) => !o)}
                  >
                    {!showSources ? "Show" : "Hide"}
                  </button>
                </div>
                <div style={{ display: showSources ? "block" : "none" }}>
                  <div className="field">
                    <p>Currency source</p>
                    <select {...register("sources.currency")}>
                      <option value="">undefined</option>
                      <option value="ldjson">ldjson</option>
                      <option value="opengraph">opengraph</option>
                    </select>
                  </div>
                  <div className="field">
                    <p>CurrencyCode source</p>
                    <select {...register("sources.currencyCode")}>
                      <option value="">undefined</option>
                      <option value="ldjson">ldjson</option>
                      <option value="opengraph">opengraph</option>
                    </select>
                  </div>
                  <div className="field">
                    <p>Image source</p>
                    <select {...register("sources.image")}>
                      <option value="">undefined</option>
                      <option value="ldjson">ldjson</option>
                      <option value="opengraph">opengraph</option>
                    </select>
                  </div>
                  <div className="field">
                    <p>Price source</p>
                    <select {...register("sources.price")}>
                      <option value="">undefined</option>
                      <option value="ldjson">ldjson</option>
                      <option value="opengraph">opengraph</option>
                    </select>
                  </div>
                  <div className="field">
                    <p>Title source</p>
                    <select {...register("sources.title")}>
                      <option value="">undefined</option>
                      <option value="ldjson">ldjson</option>
                      <option value="opengraph">opengraph</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ marginTop: 20 }}>
                <h3 style={{ margin: "0 30px" }}>DOM Selectors</h3>
                {/* TODO review fields */}
                {pluginDomSelectorFields.map((f) => !f.hide && renderField(f))}
              </div>
            </div>
            <div className="divided">
              {pathname !== "/plugins/new" && (
                <div
                  className="flex-v-center"
                  style={{
                    margin: "10px 0 0 10px",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", paddingRight: 30 }}>
                    <button
                      disabled={state.loading}
                      type="button"
                      className="btn-white"
                      onClick={onResetCache}
                    >
                      Reset cache
                    </button>
                    <Tooltip
                      text="Resets the 24h cache (by Product API) for the domain."
                      size={400}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      disabled={state.loading}
                      type="button"
                      className="btn-white red"
                      onClick={delPlugin}
                    >
                      Delete domain
                    </button>
                    <Tooltip
                      text="Permanently deletes the domain from the plugins."
                      size={400}
                    />
                  </div>
                </div>
              )}
              <div style={{ marginTop: pathname !== "/plugins/new" ? 40 : 0 }}>
                {!domainRules.whitelisted.length ? null : (
                  <div style={{ margin: "10px 0" }}>
                    <h3 style={{ margin: 0 }}>Whitelisted query parameters</h3>
                    {domainRules.whitelisted.map((dr, i) => (
                      <p key={dr} style={{ width: "auto" }}>
                        {`${i + 1}. ${dr}`}
                      </p>
                    ))}
                    {domainRules.whitelisted.length ? null : (
                      <p style={{ width: "auto" }}>Empty list</p>
                    )}
                  </div>
                )}
                {!domainRules.blacklisted.length ? null : (
                  <div style={{ margin: "10px 0" }}>
                    <h3 style={{ margin: 0 }}>Blacklisted query parameters</h3>
                    <i style={{ margin: 0 }}>
                      These overwrite any whitelisting rule. On top of these,
                      the default blacklisted params are also applied e.g.
                      common tracking parameters.
                    </i>
                    {domainRules.blacklisted.map((dr, i) => (
                      <p key={dr} style={{ width: "auto" }}>
                        {`${i + 1}. ${dr}`}
                      </p>
                    ))}
                    {domainRules.blacklisted.length ? null : (
                      <p style={{ width: "auto" }}>Empty list</p>
                    )}
                  </div>
                )}
                <div className="flex-v-center">
                  <h3 style={{ margin: 0 }}>Test the scraper</h3>
                  <Tooltip
                    text="Sends a request directly to the scraper. Use it to test current settings."
                    size={520}
                  />
                </div>
                <div className="field" style={{ overflow: "hidden" }}>
                  <p style={{ width: "auto" }}>URL</p>
                  <input
                    type="text"
                    className="long"
                    {...register("targetUrl")}
                  />
                  <Tooltip
                    text="Paste a valid product URL to test."
                    size={300}
                  />
                </div>
                <div className="field" style={{ overflow: "hidden" }}>
                  <p style={{ width: "auto" }}>Proxy&apos;s geo country code</p>
                  <input type="text" {...register("proxyGeoCountryCode")} />
                  <Tooltip
                    text="Locale used by the proxy (if used)."
                    size={300}
                  />
                </div>
                <div className="flex-v-center med-padding">
                  <button
                    className="btn-white"
                    disabled={state.loading}
                    type="button"
                    onClick={handleSubmit(test)}
                  >
                    Test URL
                  </button>
                </div>
              </div>
              {state.loading && <Loader />}
              {testResponse && !testCleared ? (
                <div className="med-padding">
                  <h4 style={{ color: "green", margin: 0 }}>Result:</h4>
                  <pre style={{ wordBreak: "break-all" }}>
                    {JSON.stringify(testResponse, null, 2)}
                  </pre>
                  <button
                    className="btn-white"
                    type="button"
                    onClick={() => setTestCleared(true)}
                  >
                    Clear result
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </form>
      <ScrollToTop />
    </div>
  );
};

export default Plugin;
